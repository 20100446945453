import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import JumbotronComponent from '@components/Jumbotron';
import Layout from '@components/Layout';
import LocalizedLink from '@components/LocalizedLink';
import LocationContext from '@components/LocationContext';
import SEO from '@components/SEO';
import SubHeading from '@components/Subheading';
import { publicIdToLocal } from '@utils/cloudinary';
import { graphql } from "gatsby";

const CareerMainCSS = styled.div`
  margin: 0 auto;
  padding-top: 100px;

  .disclaimer {
    width: 80%;
    margin: 0 auto;

    color: red;
    font-size: 1rem;
    text-align: center;
  }

  .in-progress {
    width: 80%;
    margin: 0 auto;
    padding-top: 1rem;
    font-size: 1rem;
    text-align: center;
  }

  .common-worker-text {
    width: 80%;
    height: 230px;
    margin: auto;
    margin-top:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    width:800px;
    @media (max-width: 800px) {
      line-height: 40px;
      font-size: 1.2rem;
      width:275px;
       flex-direction: column;
       font-size: 1.2rem;
       font-weight: 600;
      }
    }
    #workerText {
      @media (max-width: 800px) {
        width:160px;
         flex-direction: column;
        }
    }

    .common-worker-text .box-item {
      width: 800px;
      word-wrap: break-word;
      word-break:keep-all;
      text-align: center;
      @media (max-width: 800px) {
          width: 175px;
          word-break:keep-all;
        }
      }
  
  #biel-recruit {
    background-image: url('/img/biel-recruit.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 150px;
    width: 150px;
    transform: translate(5%, 0);
  }
`;

export const MTCardCSS = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .mt-card {
    height: 375px;
    width: 375px;
    cursor: pointer;
    background-image: ${props => props.imgUrl};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 20px;
    @media (max-width: 800px) {
      height: 250px;
      width: 250px;
    }
  }
 
`;

const MTCard = ({ imgUrl }) => {
  const { i18n } = useTranslation();

  const jumpDetails = () => {
    const pathname = window.location.pathname.replace(/career/, "career-blog")
    window.location.replace(pathname)
  }

  return (
    <LocalizedLink to="/career" language={i18n.language}>
      <MTCardCSS imgUrl={`url(${imgUrl})`} onClick={jumpDetails}>
        <div className="mt-card" />
      </MTCardCSS>
    </LocalizedLink>
  );
};

const ExperienceHireComponent = ({ t }) => {
  return (
    <div style={{ paddingTop: '2rem' }}>
      <SubHeading text={t('experienced')} />
      <div className="disclaimer">{t('disclaimer')}</div>
      <CommonWorkerComponent t={t} />
      <div className="in-progress">{t('inProgress')}</div>
    </div>
  );
};

const CommonWorkerComponent = ({ t }) => {
  return (
    <div>
      <div className="common-worker-text">
        <div className='box-item'>{t('partnerWantedText')}</div>
        <div>
          <a href='https://cloud1-1gmdd42g283a3e60-1309544290.tcloudbaseapp.com/jump-mp.html?sign=9ae8537af639bfeefd224b8bea6e2fd5&t=1645419982'>
            <div id="biel-recruit" />
          </a>
        </div>
      </div>
    </div>
  );
};

const CareerPage = ({ location }) => {

  const { t, i18n } = useTranslation();

  return (
    <Layout location={location} t={t} i18n={i18n}>
      <SEO
        title={t('title')}
        description={t('description')}
        pathname={location.pathname}
      />
      <LocationContext.Consumer>
        {props => (
          <>
            <JumbotronComponent
              t={t}
              imgUrl={publicIdToLocal["biel/careers_jumbotron_awox04"]}
              isJumbotron
            />
            <CareerMainCSS>
              <ExperienceHireComponent t={t} />
            </CareerMainCSS>
          </>
        )}
      </LocationContext.Consumer>
    </Layout>
  );
};

CareerPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {ns: {in: ["career","footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default CareerPage;
