
export const publicIdToLocal = {
  // home page
  "biel/biel-jumbotron-video_sg4j1c": "/video/biel-jumbotron-video.mp4",
  "biel/glassyv3_uwj1xw": "/img/glassyv3.png",
  "biel/donation_tiianc": "/img/donation.jpg",

  // about page
  "biel/factory_jumbotron_hx6g5j": "/img/factory_jumbotron.jpg",
  "biel/about_carousel_bg_sxovop": "/img/about_carousel_bg.jpg",
  "biel/history_small_lj6mhw": "/img/history_small.jpg",

  // about honor
  "biel/patent/patent_one": "/img/专利集1.jpg",
  "biel/patent/patent_two": "/img/专利集2.jpg",
  "biel/patent/patent_three": "/img/专利集3.jpg",
  "biel/patent/patent_four": "/img/专利集4.jpg",
  "biel/patent/patent_five": "/img/专利集5.jpg",
  "biel/patent/patent_six": "/img/专利集6.jpg",
  "biel/patent/patent_serven": "/img/专利集7.jpg",
  "biel/patent/patent_eight": "/img/专利集8.jpg",

  "biel/patent/charity_one": "/img/荣誉照片13.jpg",
  "biel/patent/charity_two": "/img/荣誉照片14.jpg",
  "biel/patent/charity_three": "/img/荣誉照片16.jpg",
  "biel/patent/charity_four": "/img/荣誉照片18.jpg",
  "biel/patent/charity_five": "/img/荣誉照片20.jpg",
  "biel/patent/charity_six": "/img/荣誉照片21.jpg",
  "biel/patent/charity_serven": "/img/荣誉照片23.jpg",

  "biel/patent/honor_1": "/img/荣誉照片1.jpg",
  "biel/patent/honor_2": "/img/荣誉照片2.jpg",
  "biel/patent/honor_3": "/img/荣誉照片3.jpg",
  "biel/patent/honor_4": "/img/荣誉照片4.jpg",
  "biel/patent/honor_6": "/img/荣誉照片6.jpg",
  "biel/patent/honor_9": "/img/荣誉照片9.jpg",
  "biel/patent/honor_10": "/img/荣誉照片10.jpg",
  "biel/patent/honor_5": "/img/国家级技术-2.jpg",
  "biel/patent/honor_7": "/img/国家认可委员会-2.jpg",
  "biel/patent/honor_11": "/img/荣誉照片11.jpg",
  "biel/patent/honor_15": "/img/荣誉照片15.jpg",
  "biel/patent/honor_22": "/img/荣誉照片22.jpg",

  // product page
  "biel/product_jumbotron_j7wbp7": "/img/product_jumbotron.jpg",
  "biel/bgnew1_ioss82": "/img/bgnew1.jpg",
  "biel/bgnew2_q67uag": "/img/bgnew2.jpg",
  "biel/cg_dcelmq": "/img/cg.jpg",
  "biel/watch_glass_htvzeo": "/img/watch_glass.jpg",
  "biel/fingerprint_sa2vgb": "/img/fingerprint.jpg",
  "biel/_DSC2384_ynknxl": "/img/_DSC2384.jpg",
  "biel/_DSC2391_ln6j4f": "/img/_DSC2391.jpg",
  "biel/plastic_d3afc8": "/img/plastic.jpg",
  "biel/firstps_rmzif4": "/img/firstps.jpg",
  "biel/secondps_asblzs": "/img/secondps.jpg",
  "biel/thirdps_ajpn4r": "/img/thirdps.jpg",
  "biel/techfirstpic-min_vqpmh0": "/img/techfirstpic-min.jpg",

  // csr page
  "biel/csr_jumbotron_ha0lvo": "/img/csr_jumbotron.jpg",
  "biel/biel_education_ohyqjr": "/img/biel_education.jpg",
  "biel/biel_csr_professional_yeaypd": "/img/biel_csr_professional.jpg",
  "biel/biel_village_tfgr11": "/img/biel_village.jpg",
  "biel/csr_poverty_2_desktop_mh3lov": "/img/csr_poverty_2_desktop.jpg",
  "biel/biel_green2_s65vmw": "/img/biel_green2.jpg",
  "biel/VetTeaching2_cvcxqz": "/img/VetTeaching2.jpg",
  "biel/biel_workers_love_jwh3d0": "/img/biel_workers_love.jpg",

  // contact page
  "biel/contact_us_jumbotron_u7nqn7": "/img/contact_us_jumbotron.jpg",
  // career-blog page
  "biel/2021_cn_mt_min_bmp9tq": "/img/2021_cn_mt_min.jpg",
  "biel/2022_full_recruit": "/img/2022_full_recruit.jpg",

  // join us page
  "biel/careers_jumbotron_awox04": "/img/careers_jumbotron.jpg",
  "biel/2021_cn_mt_mbuyyv": "/img/2021_cn_mt.jpg",
  "biel/2022_intercept_recruit": "/img/2022_intercept_recruit.jpg",
};
